import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import * as React from 'react'

export default function Dots(props: {
  fontSize? : string
}){
  
  const [dots, setDots] = useState('.')

  const { t } = useTranslation()

  const updateDots = () => {
    if(dots.length < 3){
      setDots((dots) => dots + '.')
      return
    }
    
    setDots((dots) => ".")
  }

  React.useEffect(() => {
    const interval = setInterval(updateDots, 1000)
    return () => clearInterval(interval)
  })
  
  return (
    <span style = {{
      fontSize: props.fontSize
    }}>
      {dots}
    </span>
  )
}
