export default function dynamicSort(iteration: number) {
  const questionNumber = iteration;

  return function (a: Vote, b: Vote) {
    const result =
      a.questions[questionNumber].value < b.questions[questionNumber].value
        ? -1
        : a.questions[questionNumber].value > b.questions[questionNumber].value
        ? 1
        : 0;
    return result;
  };
}
