import * as microsoftTeams from '@microsoft/teams-js'

const setupUserTheme = async(setTheme : any) : Promise<void>=> {
  return new Promise(async(resolve, reject) => {

    await microsoftTeams.app.initialize()
  
    const context = await microsoftTeams.app.getContext();
    
    setTheme(context.app.theme || 'default')
    resolve()
  })
}

export { setupUserTheme }