import '../styles/navbar.css';
import '../styles/TeamsCreateQnr.css'
import TeamsNavbar from '../components/TeamsNavbar'
import TeamsVerifyView from '../components/TeamsVerifyView';
import { setupUserTheme } from '../functions/getUserTheme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import TeamsCreateQnrBtn from '../components/TeamsCreateQnrBtn';
import { global } from '../../src/types/global'
import LoadingOverlay from '../components/LoadingOverlay';

export default function TeamsCreateQnrOther(){

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [theme, setTheme] = React.useState<TeamsColorTheme>('default')
  const [ loading, setLoading ] = React.useState(false);

  const redirectToOther = () => {
    navigate('/ms-teams/pages/start-qnr')
  }

  React.useEffect(() => {
    setupUserTheme(setTheme)
  }, [])

  return (
    <>
      <LoadingOverlay
        show = {loading}
      />
      <TeamsVerifyView/>
      <TeamsNavbar theme={theme}/>

      <main>

        <TeamsCreateQnrBtn
          buttonText='Start Team Check-In'
          qnrType={3}
          setLoading={setLoading}
          />
        <TeamsCreateQnrBtn
          buttonText='Start Mission Statement Check'
          qnrType={4}
          setLoading={setLoading}
          />
        <TeamsCreateQnrBtn
          buttonText='Start Change Communication Survey'
          qnrType={2}
          setLoading={setLoading}
        />

        <div
          className='diff-qnr-cnt'
        >

          <p
            className={`diff-qnr-p ${theme}`}
          >
            Start a Meeting Follow-Up?&nbsp;
          </p>

          <button
            onClick={redirectToOther}
            className={`diff-qnr-link ${theme}`}
          >
            {t('Click here!')}
          </button>

        </div>

      </main>
    
    </>
  )
}