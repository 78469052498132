import DeleteIconSvg from '../images/Icon_delete_cross.svg'
import '../styles/RegularMeetingEmail.css'
import * as React from 'react';

export default function RegularMeetingEmail(props: {
  email : string,
  index : number,
  removeEmail : (emailToRemove: string) => void;
}){
  return(
    <div
      id={`email-${props.index}-cnt`}
      className='email-cnt'
    >

      <span
        className='email-txt-cnt'
      >
        {props.email}
      </span>

      <button
        className='email-del-btn'
        title='Delete participant'
        onClick={() => {
          console.log('clicking/')  
          props.removeEmail(props.email);
        } }
        type='button'
        style={{
          backgroundImage: `url(${DeleteIconSvg})`
        }}
      ></button>
    </div>
  )
}