import axios from 'axios'
import * as microsoftTeams from '@microsoft/teams-js'
import * as React from 'react'

export default function TeamsAuthCallback(){

  const baseUrl = process.env.REACT_APP_BASE_URL

  const confirmCallback = () => {

    const params = new URLSearchParams(window.location.search).toString()
    
    microsoftTeams.app.initialize().then( () => {

      axios
      .get(`${baseUrl}.netlify/functions/callback?${params}`)
      .then( async(response) => {
        console.log(`REPOSNSE: ${JSON.stringify(response)}`)
        if(response.status === 200){
          microsoftTeams.authentication.notifySuccess();
        }else{
          microsoftTeams.authentication.notifyFailure();
        }
      }).catch( async() => {
        microsoftTeams.authentication.notifyFailure();
      })  

    })
  }

  React.useEffect(confirmCallback, [])

  return <>

  </>
}