import * as React from 'react'
import '../styles/Dashboard.css'
import DashboardQnrs from '../components/DashboardQnrs'
import RegularMeetings from './RegularMeeting/RegularMeetings'
import {Navigate, Route, Router, Routes} from 'react-router-dom'
import QuestionnaireInfoPage from './QuestionnaireInfoPage'
import RegularMeeting from './RegularMeeting'
import DashboardRoutes from '../components/DashboardRoutes'

export default function Dashboard(){
  
  return (
    <>

      <Routes>
        <Route element={<DashboardRoutes />} >
          <Route path='questionnaires' element={<DashboardQnrs />} />
          <Route path='regular-meetings' element={<RegularMeetings />} />
        </Route>
        <Route path='questionnaire/:qnrId' element={<QuestionnaireInfoPage />} />
        <Route path='regular-meeting/:customId' element={<RegularMeeting />} />

        <Route path='*' element={<Navigate to='questionnaires' replace />} />
      </Routes>
    </>
  )
}
