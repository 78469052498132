
import GIFLoading from './GIFLoading';
import '../styles/RegularMeetingsButton.css'

import { useTranslation } from 'react-i18next';

import * as React from 'react';
import TypeIcon from './TypeIcon';

export default function RegularMeetingButton(props: {
  meeting: {
    name: string,
    type: number,
    customId: string
  },
  startRegularMeeting: (regularMeetingId : string) => void
}){
      
  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);

  return (
    <button
      className='reg-meeting-btn'
      onClick={() => {
        props.startRegularMeeting(props.meeting.customId)
        setIsLoading(true);
      }}
    >
      <div
        className={`gif-loading-align ${isLoading ? 'show-gif-loading' : ''}`}
      >
        <GIFLoading show = {isLoading}/>
      </div>

      <p
        style={{
          maxWidth: '80px',
          overflow: 'hidden'
        }}
      >
        {t(props.meeting.name)}
      </p>
      
      <TypeIcon type={props.meeting.type || 1} className='type-icon'/>
      
    </button>

  )
}