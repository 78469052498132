import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function CountCnt(props: {
  property : string,
  count : number,
  unit? : string,
}){

  const { t } = useTranslation();

  return (
    <div
      className='count-cnt'
    >
      <section
        className='count-cnt-content'
      >
        <span
          className='count-cnt-num'
        >
          {props.count}
        </span>

       {props.unit && 
        <span
          className='count-cnt-unit'
        >
          {t(props.unit)}
        </span>
       }

      </section>
      <section
        className = 'count-cnt-heading-cnt'
      >
        <span
          className='count-cnt-heading'
        >
          {t(props.property)}
        </span>
      </section>

      
    </div>
  )
}
