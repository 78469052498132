import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import {ReactComponent as IconRightArrow} from '../../images/Icon-right-arrow.svg';
import axios from 'axios';
import style from './Home.module.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';

function Home() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [findQuestionnaireLoading, setFindQuestionnaireLoading] = useState(false);
  const [qnrId, setQnrId] = useState({ qnrId: '' });

  //request to server to search with qnr ID
  const handleQuestionnaireSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFindQuestionnaireLoading(true);
    await axios
      .get(baseURL + '.netlify/functions/find-qnr', {
        params: { qnrId: qnrId.qnrId },
      })
      .then(() => {
        navigate(`/${qnrId.qnrId}`);
      })
      .catch(() => {
        setFindQuestionnaireLoading(false);
        alert("We couldn't find questionnaire " + qnrId.qnrId);
      });
  };
  const handleInsertCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQnrId({ ...qnrId, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div 
        className={style.homeBody}
      >
        <form
          onSubmit={(e) => handleQuestionnaireSearch(e)}
          id="search-form"
          className={style.form}
          method="PUT"
        >
          <div
            className={style.enterCodeDiv}
          >
            <a
              className= {style.inviteRecievedCapt}
            >
              {t('Invitation received?')}
            </a>
            <div className={style.idField}>
              <a style={{ color: '#016fa0', fontSize: '30px' }}>#</a>
              <input
                type="text"
                id="qnrId"
                className={style.qnrId}
                name="qnrId"
                placeholder={t('insert code') || ''}
                onChange={(e) => handleInsertCodeChange(e)}
              ></input>
              {!findQuestionnaireLoading ? (
                <Button className={style.searchBtn} variant="contained" type="submit">
                  <IconRightArrow />
                </Button>
              ) : (
                <LoadingButton
                  style={{
                    backgroundColor: 'white',
                  }}
                  loading={true}
                  loadingIndicator={
                    <CircularProgress style={{ color: '#016fa0' }} />
                  }
                />
              )}
            </div>
          </div>

          <a
            className={style.homeH1}
          >
            {t('Increase team effectiveness with smart surveys!')}{' '}
          </a>

          <a
            className={style.homeH2}
          >
            {' '}
            {t(
              'Support consequent development of your team through regular, structured feedback!',
            )}{' '}
          </a>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Button
              style={{
                borderRadius: 9,
                color: 'white',
                padding: '10px 25px',
                fontSize: '20px',
                margin: '30px',
                marginTop: '10px',
                backgroundColor: '#016fa0',
                minWidth: '20rem',
              }}
              className={style.newQnrBtn}
              variant="outlined"
              onClick={() => navigate('/choose-qnr-type')}
            >
              {t('Start now!')}
            </Button>
            <a
              href="/learn-more"
              style={{
                color: '#016fa0',
                fontSize: '20px',
                margin: '50px',
                fontWeight: '100',
              }}
            >
              {t('More info')}
            </a>
          </div>
        </form>
      </div>
    </>
  );
}
export default Home;
