import i18n from '../../i18n';
import { useState, useEffect } from 'react';
import style from './Navbar.module.css';
import axios from 'axios'
import * as React from 'react';

export default function Languages(props: {
  theme?: string
}) {

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const getUserPreferredLanguage = () => {

    const langs_as_in_storage = [
      'en',
      'de'
    ]

    const user_lang = navigator.language
    
    langs_as_in_storage.forEach(lang => {
      if(user_lang.includes(lang)){
        return lang;
      }
    })
    
    return 'de';
  } 

  const requestLanguageChange = (newLanguage : string) => {
    axios
    .put(
      `${baseUrl}.netlify/functions/update-user-language`,
      JSON.stringify({
        newLanguage: newLanguage
      })
    )
  }

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('language') || getUserPreferredLanguage() || 'de',
  );

  const lngs = {
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' },
  };

  useEffect(() => {
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    requestLanguageChange(lng)
    setSelectedLanguage(lng);
  };

  return (
    <>
      <div className={style.languagesContainer}>
        {Object.keys(lngs).map((lng) => (
          <button
            className={`${style.languageButton} ${style[props.theme || '']}`}
            type="submit"
            key={lng}
            onClick={() => handleLanguageChange(lng)}
            disabled={selectedLanguage === lng}
          >
            {lng}
          </button>
        ))}
      </div>
    </>
  );
}
