import Navbar from './Navbar/Navbar'
import { useEffect } from 'react';
import * as React from 'react';

function TeamsNavbar(props: {
  theme?: string
}) {

  const changeRedirectHref = () => {
    const link = document.getElementById('home-nav') as HTMLAnchorElement;
    link.href = '#'
  }

  const removeAccountAnchor = () => {
    const link = document.getElementById('nav-acnt-link') as HTMLAnchorElement;
    link.style.display = 'none';
  }

  useEffect(changeRedirectHref, [])
  useEffect(removeAccountAnchor), [];

  return (
    <Navbar theme={props.theme}/>
  )

}

export default TeamsNavbar;


