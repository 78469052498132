import { Link } from 'react-router-dom';
import '../styles/navbar.css';
import '../styles/TeamsConfig.css'
import { setupUserTheme } from '../functions/getUserTheme';
import TeamsNavbar from '../components/TeamsNavbar'
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js'
import { global } from '../../src/types/global'
import axios from 'axios'

export default function TeamsConfig(){
	
	const { t } = useTranslation();

	//used for NGROK testing only!!!
	//const baseUrl = 'https://firefly-powerful-ultimately.ngrok-free.app'
	const baseUrl = process.env.REACT_APP_BASE_URL;
	const mainRef = useRef<HTMLDivElement | null>(null);
	const [theme, setTheme] = React.useState<TeamsColorTheme>('default')

  const onLoad = () => {    

		// mainRef.current?.scrollIntoView({behavior: "instant"});

		const setupTheme = (theme: any) => {
			setupUserTheme(setTheme);
			if(theme) {
				console.log(`THEME: ${theme}`)
				document.body.className = 'theme-' + (theme === 'default' ? 'light' : theme)
				localStorage.setItem(`user-preference-theme`, theme === 'default' ? `light` : theme)
			}
		}
	
		const registerConfig = async(url: string) => {
			microsoftTeams.pages.config.setValidityState(true)
			
			microsoftTeams.pages.config.registerOnSaveHandler(async(saveEvent) => {
	
				const configPromise = microsoftTeams.pages.config.setConfig({
					websiteUrl:url,
					contentUrl:url,
					entityId:url        
				})
	
				configPromise.then( () => {
					console.log("NO ERRORR ")
					saveEvent.notifySuccess()
				}).catch(err => {
					console.log(err)
					saveEvent.notifyFailure('ERRRRORRRRR')
				})
			})
		}

    microsoftTeams.app.initialize().then( () => {
      microsoftTeams.app.getContext().then(async context => {
          //respect user's theme
          if(context && context.app.theme){
            setupTheme(context.app.theme)
          }
					console.log('config-reg');
          console.log(registerConfig(`${baseUrl}ms-teams/pages/loading`));
      })
    })
	}

  useEffect(onLoad)

	return (
		<>
		
			<TeamsNavbar theme={theme}/>
				
			<main ref = {mainRef}>
				<p className = {`capt-p ${theme}`}>
					{t('Increase team effectiveness with smart surveys!')}
				</p>
						
				<p className = {`about-capt-p ${theme}`}>
					{t('Support consequent development of your team through regular, structured feedback!')}
				</p>
				<div className = 'links-cnt'>

					<button
						className = 'links-cnt-link more-info'
						style = {{
							textDecoration: 'none'
						}}
						onClick = {() => window.open('/privacy', '_blank')}
					>
						{t('Privacy Policy')}
					</button>

					<button 
					 	className = 'links-cnt-link eula-info' 
	 					onClick = {() => window.open('/user-agreement', 'user-agreement')}
						style = {{
							textDecoration: 'none'
						}}
					>
						{t('EULA')}
					</button>

					<button
					 	className = 'links-cnt-link contact-link'
						onClick = {() => window.open('/contact', '_blank')}
						style = {{
							textDecoration: 'none'
						}}
					>
						{t('Contact Us')}
					</button>

				</div>
			</main>
		</>
		)
}

