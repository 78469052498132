import * as React from 'react';
import axios from 'axios';

type TLoginParams = { key: string, email: string, keepLoggedIn: boolean, teams?: boolean }

type TAuthContext = {
  user: string | undefined;
  logout: () => Promise<void>;
  login: (params: TLoginParams) => Promise<boolean>;
}

let AuthContext: React.Context<TAuthContext>;

export function AuthProvider({ children }: { children: any} ) {

  // user as in the email of the user; undefined means no user is currently logged in;
  const [ user, setUser ] = React.useState<string | undefined>();

  const logout = async(): Promise<void> => {
    try{
      await axios.get('/.netlify/functions/log-out');
    }catch{}

    setUser(undefined);
  }

  const login = async(params: TLoginParams): Promise<boolean> => {
    try{
      const response = await axios.get('/.netlify/functions/enter-account', { params });

      setUser(params.email);
      return !!response.data.message;
    }catch{
      return false;
    }
  }

  const fetchUser = (): void => {
    axios
    .get('/.netlify/functions/get-account-name')
    .then((response) => {
      setUser(response.data.message);
    })
  }

  React.useEffect(fetchUser);

  AuthContext = React.createContext({login, logout, user});
  
  return (
    <AuthContext.Provider value={{user, logout, login}}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return React.useContext(AuthContext);
}
