import React from 'react';
import dynamicSort from '../functions/dynamicSort';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const ResultsAccordionPDF = (props: {
  questionSet: Array<Question>;
  lang: string;
  name: string;
  votes: Array<Vote>;
  type: number;
}) => {
  const { t } = useTranslation();
  const getH = (currentNumber: number, questionIndex: number) => {
    let h = -5;
    for (let voteI = 0; voteI < currentNumber; voteI++) {
      if (
        props.votes[voteI].questions[questionIndex].value ===
        props.votes[currentNumber].questions[questionIndex].value
      ) {
        h += 5;
      }
    }
    return h;
  };
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <View style={styles.title}>
            <Text>{props.name}</Text>
            <Text style={styles.type}>
              {props.type === 1 &&
                'Meeting Follow-Up: ' +
                  new Date()
                    .toISOString()
                    .slice(0, 16)
                    .replace('T', ' ')
                    .replace('-', '/')
                    .replace('-', '/')}
            </Text>
            <Text style={styles.type}>
              {props.type === 3 &&
                'Team Check-In: ' +
                  new Date()
                    .toISOString()
                    .slice(0, 16)
                    .replace('T', ' ')
                    .replace('-', '/')
                    .replace('-', '/')}
            </Text>
            <Text style={styles.type}>
              {props.type === 4 &&
                'Mission Statement Check: ' +
                  new Date()
                    .toISOString()
                    .slice(0, 16)
                    .replace('T', ' ')
                    .replace('-', '/')
                    .replace('-', '/')}
            </Text>
            <Text style={styles.type}>
              {props.type === 2 &&
                'Change Communication Survey: ' +
                  new Date()
                    .toISOString()
                    .slice(0, 16)
                    .replace('T', ' ')
                    .replace('-', '/')
                    .replace('-', '/')}
            </Text>
          </View>
          {props.questionSet.map((question, questionIndex) => (
            <View
              wrap={props.votes.length < 10 ? false : true}
              style={styles.question}
              key={questionIndex}
            >
              <View style={styles.qusetion}>
                <Text style={styles.qusetion}>
                  {props.lang === 'en' ? question.name : question.nameDe}
                </Text>
              </View>

              <View style={styles.line}>
                {props.votes
                  .sort(dynamicSort(questionIndex))
                  .map((vote: Vote, i: number) => {
                    return (
                      <View
                        key={i}
                        wrap={false}
                        style={
                          StyleSheet.create({
                            lineCircle: {
                              width: '0px',
                              height: '0px',
                              backgroundColor: vote.color,
                              padding: '8px',
                              borderRadius: '100px',
                              position: 'absolute',
                              left:
                                vote.questions[questionIndex].value - 2 + '%',
                              bottom: getH(i, questionIndex) + 'px',
                              display:
                                vote.questions[questionIndex].value < 0
                                  ? 'none'
                                  : undefined,
                            },
                          }).lineCircle
                        }
                      >
                        <Text
                          style={
                            StyleSheet.create({
                              lineInitials: {
                                right:
                                  (vote.initials?.length
                                    ? vote.initials?.length
                                    : 0) *
                                    2 +
                                  'px',
                                bottom: '5px',
                                fontSize: '8px',
                                fontFamily: 'Helvetica-Bold',
                              },
                            }).lineInitials
                          }
                        >
                          {vote.questions[questionIndex].value < 0
                            ? ''
                            : vote.initials}
                        </Text>
                      </View>
                    );
                  })}
              </View>

              <View style={styles.answersBox}>
                {props.votes
                  .sort(dynamicSort(questionIndex))
                  .map((vote: Vote, i: number) => {
                    if (vote.questions[questionIndex].value >= 0) {
                      return (
                        <View style={styles.answer} key={i}>
                          <View
                            style={
                              StyleSheet.create({
                                circle: {
                                  width: '0px',
                                  height: '0px',
                                  backgroundColor: vote.color,
                                  padding: '8px',
                                  borderRadius: '100px',
                                },
                              }).circle
                            }
                          ></View>
                          <Text
                            style={
                              StyleSheet.create({
                                initials: {
                                  right:
                                    8 +
                                    (vote.initials?.length
                                      ? vote.initials?.length
                                      : 0) *
                                      2 +
                                    'px',
                                  top: '4px',
                                  fontSize: '8px',
                                  fontFamily: 'Helvetica-Bold',
                                },
                              }).initials
                            }
                          >
                            {vote.initials}
                          </Text>
                          <View style={styles.alignAnswerText}>
                            <Text style={styles.answerText}>
                              <Text style={styles.bold}>
                                {vote.questions[questionIndex].value +
                                  '% ' +
                                  vote.username +
                                  ': '}
                              </Text>
                              <Text>{vote.questions[questionIndex].text}</Text>
                            </Text>
                          </View>
                          <View style={styles.spaceDisplay}>
                            {vote.questions[questionIndex].text.length >
                              100 && <View style={styles.space}></View>}
                            {vote.questions[questionIndex].text.length >
                              120 && <View style={styles.space}></View>}
                            {vote.questions[questionIndex].text.length >
                              140 && <View style={styles.space}></View>}
                            {vote.questions[questionIndex].text.length >
                              180 && <View style={styles.space}></View>}
                            {vote.questions[questionIndex].text.length >
                              220 && <View style={styles.space}></View>}
                          </View>
                        </View>
                      );
                    } else {
                      return (
                        <View style={styles.answer} key={i}>
                          <View
                            style={
                              StyleSheet.create({
                                circle: {
                                  width: '0px',
                                  height: '0px',
                                  backgroundColor: vote.color,
                                  padding: '8px',
                                  borderRadius: '100px',
                                },
                              }).circle
                            }
                          ></View>
                          <Text
                            style={
                              StyleSheet.create({
                                initials: {
                                  right:
                                    8 +
                                    (vote.initials?.length
                                      ? vote.initials?.length
                                      : 0) *
                                      2 +
                                    'px',
                                  top: '4px',
                                  fontSize: '8px',
                                  fontFamily: 'Helvetica-Bold',
                                },
                              }).initials
                            }
                          >
                            {vote.initials}
                          </Text>
                          <View style={styles.alignAnswerText}>
                            <Text style={styles.answerText}>
                              <Text style={styles.bold}>
                                {vote.username + ': '}
                              </Text>
                              <Text>{t('Not Applicable')}</Text>
                            </Text>
                          </View>
                        </View>
                      );
                    }
                  })}
              </View>
            </View>
          ))}
          {/* <Text>
            {new Date()
              .toISOString()
              .slice(0, 16)
              .replace('T', ' ')
              .replace('-', '/')
              .replace('-', '/')}
          </Text> */}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  type: {
    fontSize: '8px',
  },
  line: {
    backgroundColor: 'black',
    width: '100%',
    height: '5px',
    borderRadius: '100px',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    paddingLeft: '5%',
    paddingRight: '5%',
    padding: '30px',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  question: {
    margin: 10,
    marginBottom: 12,
  },
  title: {
    alignItems: 'center',
    fontSize: '30px',
    marginBottom: '35px',
  },
  answer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '18px',
  },
  circle: {
    fontFamily: 'Helvetica-Bold',
    width: '0px',
    height: '0px',
    backgroundColor: 'red',
    padding: '8px',
    borderRadius: '100px',
  },
  alignAnswerText: {
    position: 'absolute',
    width: '90%',
    left: '7%',
  },
  answerText: {
    padding: '1px',
    right: '3px',
    fontWeight: 'black',
  },
  answersBox: {
    border: 'solid 1px black',
    borderRadius: '5px',
    borderColor: 'gray',
    borderWidth: '1px',
    padding: '20px',
    marginBottom: '48px',
    marginTop: '15px',
    fontSize: '12px',
  },
  qusetion: {
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '14px',
    fontFamily: 'Helvetica-Bold',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  space: {
    height: '20px',
    width: '1000px',
  },
  spaceDisplay: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default ResultsAccordionPDF;
