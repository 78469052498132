import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/results.css';
import { useTranslation } from 'react-i18next';
import DotsLineResults from '../components/DotsLineResults';
import SetDurationModal from '../components/SetDurationModal';
import QuestionnaireSettings from '../components/QuestionnaireSettings';
import * as React from 'react';
import TypeIcon from '../components/TypeIcon';

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function Results() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //Sets default questaionnare data
  const defaultData = {
    customId: '9999',
    keyStr: 'xxxxxxxxx',
  };
  const [allVotes, updateVotes] = useState<Questionnaire>(defaultData);

  //To add loading visualisation
  const [loading, setLoading] = useState(true);

  //Get qnr and string from the URL to access results
  const { qnrId } = useParams();

  const [qnrType, setQnrType] = useState(0);

  // knows if request to be redirected to login page has been made
  let redirected = false; 
  //Axios request to server
  const getVotes = async () => {
    console.log('making request');
    await axios
      .get(`/.netlify/functions/get-results`, {
        params: {
          qnrId: qnrId,
        },
      })
      .then((response) => {
        if (response.data[0]) {
          updateVotes(response.data[0]);
        }
        setLoading(false);
      })
      .catch((e: authenticationError) => {
        let logged = true;
        if (e.response.status === 401) {
          logged = false;
          if(!redirected){
            localStorage.setItem('afterLogInLocation', window.location.href);
            redirected = true            
            navigate('/login');
          }
        }
        setLoading(false);
      });
  };
  const getVotesCheckDuration = async () => {
    console.log('making request');
    await axios
      .get('/.netlify/functions/get-results', {
        params: {
          qnrId,
        },
      })
      .then((response) => {
        const qnr = response.data;
        updateVotes(qnr);
        setQnrType(qnr);
        if (
          !qnr.duration &&
          qnr.type === 1
        ) {
          setModalOpen(true);
        }
        setLoading(false);
      })
  };
  const [modalOpen, setModalOpen] = useState(false);

  //On initial load in order to open modal if duration is unsed
  useEffect(() => {
    getVotesCheckDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Gets updated duration when the modal is closed, but doesn't re-open if it's still unset
  useEffect(() => {
    getVotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, setModalOpen, loading, setLoading]);

  return (
    <>
      {modalOpen && (
        <SetDurationModal
          setOpen={setModalOpen}
          open={modalOpen}
          qnrId={qnrId || ''}
          duration={allVotes.duration}
        />
      )}
      {!loading ? (
        <>

          <div className="two-element-flexbox">
            
            <TypeIcon type={qnrType} id = 'distribuIcon'/>
            <h1
              id="results-h1"
              style = {{
                whiteSpace:   'normal',
                width:        '80%',
                overflowWrap: 'break-word'
              }}
            >
              {allVotes.qnrName || t('Results')}
            </h1>
            <div style={{ width: '7em' }}></div>
          </div>

          <QuestionnaireSettings
            customId={qnrId || ''}        
          />          
          {allVotes.votes && allVotes.votes.length && (
            <DotsLineResults name={allVotes.qnrName || t('Results')} type={allVotes.type} votes={allVotes.votes} />
          )}
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
