import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/notFound.css';
import * as React from 'react';

export default function NotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  return (
    <>

      <div id="error-body">
        <div id="error-wrapper">
          <h1>{ t("Page not Found") }!</h1>
          <Button
            variant="contained"
            style={{
              borderRadius: 9,
              backgroundColor: 'black',
              padding: '18px 36px',
              fontSize: '18px',
              maxWidth: '335px',
              width: '100%',
              margin: '0 auto',
              // marginBottom: '5rem',
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            { t("Go to Home page") }
          </Button>
        </div>
      </div>
    </>
  );
}
