import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/vote.css';
import { Button, CircularProgress, Slider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { MAX_QUESTION_ANSWER } from '../constants';

type Answer = {
  text: string,
  value: number,
  disabled?: boolean
}

export default function Vote() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { qnrId } = useParams();
  const [loading, setLoading] = useState(false);

  const [questionSet, setQuestionSet] = useState<Question[]>([]);
  const [qnrType, setQnrType] = useState();
  const [workingQuestion, setWorkingQuestion] = useState('');
  
  const [username, setUsername] = useState('');

  const [ answers, setAnswers ] = useState<Answer[]>([]); 

  const toggleQuestion = (questionIndex: number) => {
    console.log(questionIndex);
    const editedAnswers = [...answers];
    editedAnswers[questionIndex].disabled = !editedAnswers[questionIndex].disabled;
    setAnswers(editedAnswers);
  }

  useEffect(() => {
    axios
    .get(`${baseURL}.netlify/functions/get-questions`, {
      params: { qnrId: qnrId },
    })
    .then((response) => {

      setQnrType(response.data.type);
      setWorkingQuestion(response.data.qnrName || '');

      console.log(response.data);
      const questionSet = response.data.message;
      if(!questionSet.length) return;

      setQuestionSet(questionSet);
      setAnswers(Array.from({length: questionSet.length}, () => ({value: MAX_QUESTION_ANSWER / 2, text: ''})))
    })
    .catch((error) => {
      console.log(error);
    });
  }, [baseURL, qnrId]);

  useEffect(() => {
    const handleTextAreaResize = () => {
      const inputFields = document.querySelectorAll('textarea[name="text"]') as unknown as HTMLTextAreaElement[];
      inputFields.forEach((inputField) => {
        inputField.style.height = 'auto';
        const textAreaHeight = inputField.scrollHeight;
        inputField.style.height = textAreaHeight + 'px';
      })
    }

    handleTextAreaResize();

    window.addEventListener('resize', handleTextAreaResize)

    return () => {
      window.removeEventListener('resize', handleTextAreaResize)
    }
  }, [questionSet])

  const handleSubmit = async (event: React.FormEvent) => {

    event.preventDefault();

    setLoading(true);

    //Remove disabled property, because it is not expected
    //Remove text from disabled question fields
    const _answers = answers.map((answer) => {
      const { text, value, disabled } = answer;
      if(!disabled){
        return { text, value }
      }
      return { text: '', value: -1 }
    })

    console.log(_answers);
    
    const timeOfVote = new Intl.DateTimeFormat('en-GB', {
      timeZoneName: 'shortOffset',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    })
    .format(new Date())
    .toString()

    const voteObject: Vote = {
      id: qnrId,
      username: username,
      questions: _answers,
      voteTime: timeOfVote
    }

    axios
    .put(baseURL + '.netlify/functions/vote', voteObject)
    .then(() => {
      navigate('/vote-success/' + qnrId);
    })
    .catch((error) => {
      console.log(error);
      alert(error.message);
    }).finally(() => setLoading(false));

  };
  function handleQuestionDisableCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    console.log(target.name);
    const questionToDisableIndex = parseInt(target.name || '-1');
    if(questionToDisableIndex < 0)
      return;
    toggleQuestion(questionToDisableIndex);
  }
  const handleNameInput = (event: React.ChangeEvent) => {
    const inputField = (event.target) as HTMLInputElement;
    const username = inputField.value;
    setUsername(username);
  };
  const handleAnswerInput = (event: React.ChangeEvent) => {
    const textArea = (event.target) as HTMLTextAreaElement
    const answer = textArea.value;
    const questionIndex = parseInt(textArea.name);
    const editedAnswers = [...answers];
    editedAnswers[questionIndex].text = answer;
    setAnswers(editedAnswers);
  }
  const handleAnswerValueChange = (event: Event, value: number | number[], activeThumb: number) => {
    const field = (event.target as unknown as {value: number, name: string})
    const questionIndex = parseInt(field.name);
    const editedAnswers = [...answers];
    editedAnswers[questionIndex].value = field.value;
    setAnswers(editedAnswers);
  }

  const lang = localStorage.getItem('language') || 'de';

  return (
    <>
      {questionSet.length > 0 ? (
        <>
          <form onSubmit={handleSubmit} id="vote-form">
            <label
              className='name-input-label'
              htmlFor='username'
            >{workingQuestion}</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="*Name"
              required
              onChange={handleNameInput}
            ></input>
            <p
              className='question-explain'
            >
              {/**{t(`Choose a number between 0 and ${MAX_QUESTION_ANSWER} to represent how much you agree with the statement of the question. `)} */}
              *{t(`Choose a number between 0 and ${MAX_QUESTION_ANSWER} to represent how much you agree with the statement of the question. `)}
            </p>
            <div id="slider-vote-wrapper">
              {questionSet.map((question, index) => (
                <div
                  className='question-container'
                  style={{}}
                  key={index}
                >
                  <div style={{ textAlign: 'center' }}>
                    <label className="rating-label">
                      {lang === 'en' ? question.name : question.nameDe}
                    </label>
                  </div>
                  <Slider
                    disabled={answers[index].disabled}
                    sx={{
                      color: 'black',
                    }}
                    valueLabelDisplay='off'
                    name={index + ''}
                    value={answers[index].value}
                    onChange={handleAnswerValueChange}
                    marks={false}
                    step={1}
                    min={0}
                    max={MAX_QUESTION_ANSWER}
                  />
                  <div className="slider-values-below">
                    <span
                      style={{
                        position: 'absolute',
                        transform: 'translateX(-50%)',
                      }}
                    >
                      0
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        transform: 'translateX(-50%)',
                        left: `${answers[index].value}%`
                      }}
                    >
                      {answers[index].value}{answers[index].value ? '%': ''}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        left: '100%',
                        transform: 'translateX(-50%)'
                      }}
                    >
                      100%
                    </span>
                  </div>
                  <textarea
                    style={{ width: '100%', fontStyle: qnrType === 5 ? 'italic' : 'normal' }}
                    disabled={answers[index].disabled}
                    maxLength={280}
                    placeholder={
                      lang === 'en'
                        ? question.exampleText
                        : question.exampleTextDe
                    }
                    value={answers[index].text}
                    id="text"
                    name={index + ''}
                    onChange={handleAnswerInput}
                  ></textarea>
                  <div className="applicable-checkbox-wrapper">
                    <input
                      id={index.toString()}
                      type="checkbox"
                      name={index.toString()}
                      onChange={handleQuestionDisableCheckbox}
                    />
                    <label htmlFor="qCheckbox">
                      {t("This question doesn't apply for me")}
                    </label>
                  </div>
                  <br />
                </div>
              ))}
            </div>

            {!loading ? (
              <Button
                style={{
                  borderRadius: 9,
                  backgroundColor: 'black',
                  padding: '18px 36px',
                  fontSize: '18px',
                  maxWidth: '335px',
                  width: '100%',
                  margin: '0 auto',
                  marginBottom: '5rem',
                }}
                variant="contained"
                type="submit"
              >
                {t('Vote ')}
              </Button>
            ) : (
              <LoadingButton
                style={{
                  borderRadius: 9,
                  backgroundColor: 'black',
                  padding: '18px 36px',
                  fontSize: '18px',
                  maxWidth: '335px',
                  width: '100%',
                  margin: '0 auto',
                  marginBottom: '5rem',
                  color: 'gray',
                }}
                loading={true}
                loadingIndicator={
                  <CircularProgress style={{ color: 'white' }} />
                }
              >
                Vote
              </LoadingButton>
            )}
          </form>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
