import * as React from 'react'
import '../styles/TeamsHowTo.css'
import TeamsNavbar from '../components/TeamsNavbar'
import { useTranslation } from 'react-i18next'
import TeamsUserAgreement from '../components/TeamsUserAgreement'
import TeamsVerifyView from '../components/TeamsVerifyView'

//dynamic import unneccesery
import image1 from '../images/how-to-1.png'
import image2 from '../images/how-to-2.png'
import image3 from '../images/how-to-3.png'
import image4 from '../images/how-to-4.png'

export default function TeamsHowTo(){

  const { t } = useTranslation()

  return (
    <div
      style = {{
        display : "flex",
        flexDirection : "column",
        backgroundColor : "white",
      }}
    >
      <TeamsVerifyView/>
      <TeamsNavbar />

      <main>
        <p id = 'intro-p'>
          {t("Welcome to Qck app!")}
        </p>

        <p id = 'intro-1-p'>
          {t("Here's a quick introduction to get started. ")}
        </p>


        <h1 className='steps-li-h'>
          {t("Add Qck to a meeting")}
        </h1>

        <ol id = 'steps-ol'>
          <li>
            {`1. ${t("Join a scheduled meeting and find the \"App\" button, as shown in the image below: ")}`}
            <br />
            <img src={image1} alt="" className='li-image'/>
          </li>

          <li>
            {`2. ${t("When you click this button a page listing all your available apps should pop up. ")}`}
          </li>

          <li>
            {`3. ${t("From this page navigate to the Qck App icon and click it. ")}`}
            <br />
            <img src={image2} alt="" className='li-image'/>
          </li>

          <li>
            {`4. ${t("The introduction page for the app should pop up. There you can read about our EULA and contact us, as well as learn more about Qck")}`}
            <br />
            <img src={image3} alt="" className='li-image'/>
          </li>

          <li>
            {`5. ${t("Click the \"Save\" button and Qck should appear in the side panel of your Teams client, ready to use! ")}`}
            <br />
            <img src={image4} alt="" className='li-image'/>
          </li>
        </ol>


      </main>

      <TeamsUserAgreement/>
      
    </div>
  )
}