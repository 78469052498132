import { useTranslation } from 'react-i18next';
import '../styles/RegularMeetingEmailInput.css'
import validateEmail from '../functions/EmailValidator';
import * as React from 'react'

const MAX_EMAIL_COUNT = 30;

export default function RegularMeetingEmailInput(props: {
  customId?: string,
  emailsList: string[],
  setEmailsList: any,
  invalidText: string,
  placeholderText?: string,
  setInvalidText: React.Dispatch<React.SetStateAction<string>>
}){

  const { t } = useTranslation();

  const newParticipant = (email: string) => {
    console.log(email);
    const inputField = (document.getElementById('participants-email-input') as HTMLInputElement)
    if(!email || !validateEmail(email)){
      inputField.value = ''
      props.setInvalidText('Please enter a valid email! ');
      return;
    }

    if(props.emailsList.length >= MAX_EMAIL_COUNT){
      inputField.value = ''
      props.setInvalidText('Maximum emails reached! ')
      return;
    }

    if(props.emailsList.includes(email)){
      inputField.value = ''
      props.setInvalidText("You can't enter the same email twice! ");
      return;
    }

    const newList = [...props.emailsList];
    newList.push(email);
    props.setEmailsList(newList);

    inputField.value = '';
    props.setInvalidText('');
  }

  const handleEnterPressOnInput = (event : any) => {
    if(event.key !== 'Enter') return;

    newParticipant((event.target as HTMLInputElement).value);
  }

  return (
    <>
      <section
        id = 'participants-list-func-btns-cnt'
      >
        <input
          type = "email"
          form='nonExistingForm'
          autoComplete = "off"
          id = 'participants-email-input'
          placeholder = {t(props.placeholderText || 'Enter email here') || ''}
          onKeyDown={handleEnterPressOnInput}
        />

      </section>
      
      <section
        className='two-el-flexbox'
      >
        {
          props.invalidText &&
            <span
              id = 'invalid-input'
            > 
              {t(props.invalidText)}
            </span>
        }
          <button
          id = 'new-email-btn'
          onClick={() => {
            console.log('clicked')
            const email = (document.getElementById('participants-email-input') as HTMLInputElement).value;
            newParticipant(email);
          }}
          type='button'
        >
          {t('Add')}
        </button>
        
      </section>

      <section
        className = 'two-el-flexbox'
      >

          {
            props.emailsList && props.emailsList.length !== 0 ?
              <span
                id = 'participants-count'
              >
                {`${props.emailsList.length} / ${MAX_EMAIL_COUNT}`} 
              </span>        
            :
              <></>
          }
        
      </section>


    </>
  )
}