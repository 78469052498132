import {NavLink} from 'react-router-dom';
import '../styles/DashboardNav.css'
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function DashboardNav(){

  const { t } = useTranslation();
  return (
    <section
      id = 'dashboard-nav'
    >
      <NavLink
        className={state => {
          return "nav-el " + (state.isActive ? "selected" : "")
        }}
        to='regular-meetings'
      >
        {t('regular meetings')}
      </NavLink>

      <NavLink
        to='questionnaires'
        className={state => {
          return "nav-el " + (state.isActive ? "selected" : "")
        }}
      >
        {t('questionnaire history')}
      </NavLink>

    </section>
  ) 
}
