import * as React from 'react';
import Navbar from '../components/Navbar/Navbar';
import UserAgreement from '../components/Footer/UserAgreementLink';
import { Outlet } from 'react-router-dom';

export default function HeaderFooter(props: {
  inTeams?: boolean
}) {
  
  return (
    <>
      <Navbar />
      <Outlet />
      <UserAgreement />
    </>
  )
}
