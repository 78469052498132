import GIFLoadingFile from '../images/GIF-Loading.gif'
import * as React from 'react';

export default function GIFLoading(props: {
  show : boolean;
}){
  return (
    <img
      src = {GIFLoadingFile}
      alt = 'gif-loading'
      className={`gif-loading ${props.show ? 'show-gif-loading' : ''}`}
    />
  )
}
