import * as React from 'react';
import { Outlet, Navigate } from "react-router-dom";
import axios from 'axios';

export const ProtectedRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | undefined>(undefined);

  const setAfterLoginLocation = (location: string) => {
    localStorage.setItem('afterLogInLocation', location);
  }

  const initializeAuthenticationState = () => {
    axios
    .get('/.netlify/functions/get-account-name')
    .then((response) => {
      const isLoggedIn = !!response.data.message;
      const location = window.location.href;
      const shouldChangeAfterLogInLocation = !location.includes('/login');
      if(shouldChangeAfterLogInLocation){
        setAfterLoginLocation(location);
      }
      setIsAuthenticated(isLoggedIn);
    }).catch((reason) => {
      setAfterLoginLocation('/');
      window.location.href = '/login';
    })
  }

  React.useEffect(initializeAuthenticationState, []);

  return (
    isAuthenticated === undefined ? <></> :
    isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  )
}
