import { Link } from 'react-router-dom';
import style from './Navbar.module.css';
import { ReactComponent as QckLogo } from '../../images/qck-logo66x76.svg';
import Languages from './Languages';
import * as React from 'react';
import MyAccount from './MyAccount';

function Navbar(props: {
  theme?: string
}) {
  return (
    <nav>
       <ul className={style.container}>
        <li>
          <a
           href= '/'
           id = 'home-nav'
          >
            <QckLogo
              className={style.logo}
            />
          </a>
        </li>
        <li
          className={style.rightContainer}
        >
          <MyAccount />
          <Languages theme={props.theme}/>
        </li>
      </ul>
    </nav>
  );
}
export default Navbar;
