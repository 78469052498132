import '../styles/RegularMeetingOverview.css'
import global from '../types/global'
 
import * as React from 'react';
import TypeIcon from './TypeIcon';

export default function RegularMeetingOverview(props: {
  regularMeeting : globalThis.RegularMeetingView,
  _key: number,
}){

  const redirToRegularMeetingPage = () => {
    window.location.href = `/dashboard/regular-meeting/${props.regularMeeting.customId}`
  }

  return (
    <div
      id = {`regular-meeting-${props._key}`}
      className = 'regular-meeting-cnt'
      onClick={redirToRegularMeetingPage}
    >

      <section
        className='regular-meeting-heading-cnt'
      >
        <TypeIcon type={props.regularMeeting.type || 1} className='qnr-icon'/>
        <h1
          className='regular-meeting-h1'
        >
          {props.regularMeeting.name}  
        </h1>      

      </section>


      <section
        className='regular-meeting-data-cnt'
      >
        <section
          className='regular-meeting-overview-cnt'
        >
          <span
            className='regular-meeting-total-qnrs regular-meeting-data-span'
          >
            {'Total created questionnaires: '}
            {props.regularMeeting.questionnaires.length}
          </span>

          <span
            className='regular-meeting-total-votes regular-meeting-data-span'
          >
            {'Total votes on all questionnaires: '}
            {
              (() => {
                let totalVotes = 0;
                for(const qnr of props.regularMeeting.questionnaires){
                  totalVotes += qnr.votes?.length || 0;
                }
                return (<> {totalVotes} </>)
              })()
            } 
          </span>

          <span
            className='regular-meeting-last-qnr regular-meeting-data-span'
          >
            {'Last questionnaire created at: '}
            {props.regularMeeting.questionnaires.length > 0 && props.regularMeeting.questionnaires[props.regularMeeting.questionnaires.length - 1].dateCreated || 'Never'}
          </span>

        </section>

        <section
          className='regular-meeting-all-cnt'
        >
          

        </section>
      </section>

    </div>
  )
}
