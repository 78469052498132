import '../styles/TeamsCreateQnr.css'
import TeamsNavbar from '../components/TeamsNavbar'
import TeamsVerifyView from '../components/TeamsVerifyView';
import { setupUserTheme } from '../functions/getUserTheme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js'
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import TeamsCreateQnrBtn from '../components/TeamsCreateQnrBtn';
import LoadingOverlay from '../components/LoadingOverlay';

const typeToNum : any = {
  'meeting-follow-up': 1,
  'change-communication-suvey': 2,
  'team-check-in': 3,
  'mission-statement-check': 4,
}


export default function TeamsCreateQnrMain(){

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [ theme, setTheme ] = React.useState('default')
  const [ loading, setLoading ] = React.useState(false);

  const redirectToOther = () => {
    navigate('/ms-teams/pages/start-qnr-other')
  }

  //Respect user's theme
  React.useEffect(() => {
    setupUserTheme(setTheme)
  }, [])

  return (
    <>
      <LoadingOverlay
        show = {loading}
      />
      <TeamsVerifyView/>

      <nav
        style={{
          width: '100%',
          alignSelf: 'flex-start'
        }}
      >
        <TeamsNavbar theme={theme}/>
      </nav>
      

      <main>

        <TeamsCreateQnrBtn
          buttonText='Start Meeting Follow-up'
          qnrType={1}
          setLoading={setLoading}
        />

        <div
          className='diff-qnr-cnt'
        >
          <p
            className={`diff-qnr-p ${theme}`}
          >
            Want a different questionnaire?
          </p>

          <button
            onClick={redirectToOther}
            className={`diff-qnr-link ${theme}`}
          >
            {t('Click here!')}
          </button>

        </div>

          

      
      </main>

    </>
  )
}