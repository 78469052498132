import '../styles/contactUs.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';

export default function ContactUs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>

      <h1>{t('Contact Us')}</h1>
      <div className="contact-us-wrapper">
        <p>{t('For technical questions or issues please contact:')}</p>
        <MailOutlineIcon fontSize="inherit" />
        <a
          href="mailto: Tosh@auxcode.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>tosh@auxcode.com </i>{' '}
        </a>
      </div>
      <div className="contact-us-wrapper">
        <p>
          {t(
            'For questions regarding professional or commercial use of this app please contact:',
          )}
        </p>
        <MailOutlineIcon fontSize="inherit" />
        <a
          href="mailto: gunhard.keil@digitalsee.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>gunhard.keil@digitalsee.com</i>{' '}
        </a>
      </div>
      <div className="contact-us-wrapper">
        <Button
          onClick={() => navigate(-1)}
          style={{
            borderRadius: 9,
            backgroundColor: 'black',
            padding: '18px 36px',
            fontSize: '18px',
            maxWidth: '335px',
            width: '100%',
            margin: '0 auto',
            marginBottom: '5rem',
          }}
          variant="contained"
        >
          {t('Back')}
        </Button>
      </div>
    </>
  );
}
