import '../styles/RegularMeetingsButton.css'
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../images/Icon-arrow-down.svg';
import { ReactComponent as PlusIcon } from '../images/Icon-plus-sign.svg';

import RegularMeetingButton from './RegularMeetingButton';

import * as React from 'react';
import axios from 'axios';
import { v4 as uuid } from 'uuid';

export default function RegularMeetingsButton(){

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [isOpen, setIsOpen] = React.useState(false);
  const [regularMeetings, setRegularMeetings] = React.useState<{
    name: string,
    type: number,
    customId: string,
  }[]>();

  const { t } = useTranslation(); 

  React.useEffect(() => {

    const url = `${baseUrl}.netlify/functions/get-regular-meetings`

    axios
    .get(url)
    .then((result) => {
      if(result.data)
        setRegularMeetings(result.data)      
      return;
    })
    
  }, [])

  const startRegularMeeting = (regularMeetingId : string) => {

    if(!regularMeetingId) return;

    const customId = Math.floor(100000 + Math.random() * 900000).toString();
    const qnrStr = uuid().slice(0, 12);
    
    const url = `${baseUrl}.netlify/functions/start-regular-meeting`

    axios
    .post(url, {
      customId: customId,
      qnrStr: qnrStr,
      regularMeetingId: regularMeetingId
    }).then( (result) => {
      window.location.href = `/dashboard/questionnaire/${customId}`
    })
    
  }

  const toggleDropdown = () => {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div
      id = 'reg-meetings-cnt'
    >

      <button
        className= {`reg-meetings-option-btn ${isOpen ? 'menu-open' : ''} ${regularMeetings ? 'hide-border' : ''}`}
        id = 'show-reg-meetings-btn'
        onClick={toggleDropdown}
      >
        {t("Regular Meetings")}
        <ArrowIcon 
          style={{
            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
            height: '1em',
            width: '1em',
            marginLeft: '4px'
          }}
        />
      </button>
      <div
        id = 'aligning-div'
        style = {{
          width: '100%',
          maxHeight: '1px',
          position: 'relative'
        }}
      >
        <section
          id = 'reg-meetings-list-cnt'
          style = {{
            display: isOpen ? 'flex' : 'none'
          }}
        >
          
          <section
            id = 'reg-meetings-list'
          >
            {
              !regularMeetings || regularMeetings.length <= 0? 
                <p
                  style = {{
                    fontSize: '1.4rem'
                  }}
                >
                  {t('You have no regular meetings set up. To do so click "New +". ')}
                </p>
              :
                regularMeetings.map( (meeting, index) => (
                  <RegularMeetingButton
                    key={index}
                    meeting={meeting}
                    startRegularMeeting={startRegularMeeting}
                  />
                ))
                // <RegularMeetingButton
                //   key = {0}
                //   meeting = {regularMeetings[0]}
                //   startRegularMeeting={startRegularMeeting}
                // />
            }
          </section>


          <button
            id = 'new-reg-meeting-button'
            className={`reg-meetings-option-btn ${regularMeetings ? 'hide-border' : ''}`}
            onClick={() => window.location.href = '/new-regular-meeting'}
          >
            {t('New')}
            <PlusIcon 
              style = {{
                width: '1em',
                height: '1em',      
              }}
            />
          </button>
        </section>
  
      </div>
      
    </div>
  )
}
