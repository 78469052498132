import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ChooseQnrType.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import RegularMeetingsButton from '../components/RegularMeetingsButton';
import { ReactComponent as IconRightArrow } from '../images/Icon-right-arrow.svg'
import TypeIcon from '../components/TypeIcon';

export default function ChooseQnrType() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ createLoading, setCreateLoading ] = React.useState(false);

  const createQuestionnaire = (type: number) => {
    const url = '/.netlify/functions/create-qnr';
    axios
    .post(url, { type })
    .then((result) => {
      const { customId } = result.data;

      if(!customId) return;

      navigate(`/create/${customId}`);
    }).finally(() => setCreateLoading(false));
    setCreateLoading(true);
  }

  interface distributionTexts {
    title: string;
    points: string[];
    type: number;
  }

  const textsArray: distributionTexts[] = [
    {
      title: t('Start Meeting Follow-Up'),
      points: [
        t('Improved meeting results'),
        t('Continuous increase in meeting efficiency'),
        t('Strengthening the team culture'),
        t('More commitment to implementation'),
      ],
      type: 1,
    },
    {
      title: t('Start Team Check-In'),
      points: [
        t('Strengthen your team cooperation in the long term'),
        t('Focus on what is relevant and helps the team to increase impact'),
        t(
          'Start a dialogue to strengthen and develop the team success factors',
        ),
      ],
      type: 3,
    },
    {
      title: t('Start Mission Statement Check'),
      points: [
        t('Bring more momentum into your organization'),
        t(
          'Challenge commitment, result-orientation and fun at work. Start a dialogue to strengthen and develop the impact of your mission',
        ),
      ],
      type: 4,
    },
    {
      title: t('Start Change Communication Survey'),
      points: [
        t('Manage your change with impact by ensuring clear communication.'),
        t(
          'Create a trusting environment where the whole team has its say Start a dialogue to support your change-impact!',
        ),
      ],
      type: 2,
    },
  ];

  return (
    <>
      <div
        style={{
          color: '#333333',
          textAlign: 'center',
          marginLeft: '10%',
          marginRight: '10%',
          width: '80%',
          fontWeight: '900',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <br />
        <a
          style={{
            color: '#333333',
            fontSize: '5.5rem',
            textAlign: 'center',
            marginTop: '30px',
            fontFamily: 'Figtree',
            marginBottom: '50px',
          }}
          id = 'page-heading'
        >
          {t('How would you like to improve your team performance today?')}{' '}
        </a>
        <a
          style={{
            color: '#333333',
            fontSize: '2.5rem',
            fontWeight: '500',
            textAlign: 'center',
            fontFamily: 'Figtree',
          }}
        >
          {t(
            'Choose from 4 ways to develop your team and improve the efficiency to achieve better results in just 2 minutes!',
          )}{' '}
        </a>
        <div
          id = 'regular-meetings-btn-cnt'
          style ={{
            marginTop: '2rem',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >        
          <RegularMeetingsButton />
        </div>
      </div>
      <div id="distributionsSpace">
        <div
          id='distributionsSpaceLoadingOverlay'
          className={createLoading ? 'show': ''}
        > </div>
        {textsArray.map((element: distributionTexts, index: number) => (
          <div id="distributionCard" key={index}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <TypeIcon type={element.type || 1} id='distributionIcon'/>
            </div>
            <p
              style={{
                fontSize: '2rem',
                height: '6.1rem',
                padding: '0px 0px 10px 0px',
                fontWeight: 'bold',
              }}
            >
              {element.title}
            </p>
            {element.points.map((text: string, key: number) => (
              <div
                key={key}
                style={{
                  fontSize: '1.5rem',
                  textAlign: 'left',
                  paddingBottom: '1rem',
                  display: 'flex',
                  margin: '1.1rem 0'
                }}
              >
                <div>
                  <IconRightArrow
                    style={{
                      width: "1.1rem",
                      height: "1.1rem",
                      marginRight: "5px"
                    }}
                  />
                </div>
                <p
                  style={{
                    margin: '0'
                  }}
                >
                  {text}
                </p>
              </div>
            ))}
            <button
              id="diusributionButton"
              style={{
                backgroundColor: '#016fa0',
                alignSelf: 'flex-end',
                width: '100%',
              }}
              className="diusributionButton"
              onClick={() => createQuestionnaire(element.type)}
            >
              {element.title}
            </button>
          </div>
        ))}
      </div>
    </>
  );
}
