import { ReactComponent as CricleSVG } from '../images/result-circle.svg';
import { useState } from 'react';
import '../styles/resultsAccordion.css';
import dynamicSort from '../functions/dynamicSort';
import * as React from 'react';
import {MAX_QUESTION_ANSWER} from '../constants';

export default function ResultsAccordion(props: {
  votes: Vote[];
  keyValue: string;
  keyMsg: string;
  index: number;
}) {
  const [selected, setSelected] = useState(false);
  const toggle = () => {
    setSelected(!selected);
  };
  return (
    <div className="accordion-wrapper" onClick={() => toggle()}>
      <div className="accordion-title">Details</div>
      <div
        className={selected ? 'accordion-content' : 'accordion-content-hidden'}
      >
        {props.votes
          .sort(dynamicSort(props.index))
          .map((vote: Vote, i: number) => {
            const voteValue = vote.questions[props.index].value;
            const voteText = vote.questions[props.index].text;
            return (
              <div
                className="small-dot-text"
                key={i}
                style={{ marginBottom: 1.5 + 'rem' }}
              >
                <div
                  className='initials-container'
                >
                  <CricleSVG
                    className="small-dot"
                    style={{ fill: vote.color }}
                  />
                  <span className="small-dot-initials">{vote.initials}</span>
                </div>
                <span className="accordion-voter-data">
                  {voteValue >= 0 ? `${voteValue}/${MAX_QUESTION_ANSWER}` : '' } {vote.username}:
                </span>
                <span className="accordion-voter-msg">
                  {voteValue >= 0 ? (voteText || "User didn't give an answer") : 'Not Applicable' }
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
