import axios from 'axios';
import '../styles/NewRegularMeeting.css'
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import MeetingTypeInput from '../components/RegularMeetingInputType';
import RegularMeetingEmailInput from '../components/RegularMeetingEmailInput';
import RegularMeetingEmail from '../components/RegularMeetingEmail';
import GIFLoading from '../components/GIFLoading';

const BUTTON_COUNT = 4;

export default function NewRegularMeeting(){
  
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [checkedOption, setCheckedOption] = React.useState<number>(0);
  const [emailsList, setEmailsList] = React.useState<string[]>([]);
  const [meetingName, setMeetingName] = React.useState<string | undefined>()

  const [isSubmitable, setIsSubmitable] = React.useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false);

  const [ invalidText, setInvalidText ] = React.useState<string>('');

  React.useEffect(() => {
    for(let meetingType = 1; meetingType <= BUTTON_COUNT; meetingType ++){
      const element = document.getElementById(`type-option-btn-${meetingType}`) as HTMLInputElement;
      if(checkedOption === meetingType){
        !element.classList.contains('active') && element.classList.add('active');
      }else{
        element.classList.remove('active');
      }
    }
  }, [checkedOption, setCheckedOption])

  React.useEffect(() => {
    setIsSubmitable(emailsList.length > 0 && meetingName && [1, 2, 3, 4].includes(checkedOption) ? true : false);
  }, [emailsList, meetingName, checkedOption])

  const onSubmit = (event : React.FormEvent) => {
    event.preventDefault();
    if(!isSubmitable) return;
    
    const meetingData = {
      name: meetingName,
      type: checkedOption,
      emails: emailsList
    }
    
    const url = `${baseUrl}.netlify/functions/new-regular-meeting`

    axios
    .post(url, {
      meetingData: meetingData
    }).then( response => {
      window.location.href = `/dashboard/regular-meeting/${response.data.customId}`
    }).catch((reason) => {
      localStorage.setItem('afterLogInLocation', window.location.href);
      window.location.href = '/login'
    })

    setIsSubmitLoading(true);
  }

  return (
    <>
      <main>
        <section
          id = 'heading'
        >
          <h1
            id = 'heading-h1'
          >
            {t('Create a regular meeting')}
          </h1>

          <h2
            id = 'heading-h2'
          >
            {t('Regular meetings allow you to create a questionnaire instantly, while sending an email to all the people you want to participate!')}            
          </h2>

        </section>

        <form
          id='reg-meeting-data-form'        
          onSubmit={onSubmit}
        >

          <section
            id = 'meeting-name'
            className='input-section'
          >
            <label
             htmlFor="meeting-name"
             id = 'meeting-name-input-label'
             className='input-label'
            >
              {t('Enter the name of the regular meeting.')}*
             </label>
            <input
              type="text"
              id = 'meeting-name-input'
              name='meetingName'
              placeholder={t('Regular meeting name') || ''}
              onChange={(event) => setMeetingName((event.target as HTMLInputElement).value)}
            />
          </section>

          <section
            id = 'meeting-type'      
            className='input-section'
          >
            <label
             htmlFor="meeting-type-input"
             className='input-label'
            >
              {t('Choose from 4 questionnaire types.')}*
            </label>
            
            <section
              id = 'meeting-type-input'
            >
              <input
                type = "hidden"
                value = {checkedOption}
                name = 'meetingType'
              />
              <div
                className='two-type-wrapper'
              >                                                  
                <MeetingTypeInput setChecked={setCheckedOption} type={1} text={t("Meeting Follow-Up")}/>
                <MeetingTypeInput setChecked={setCheckedOption} type={2} text={t("Change Communication Survey")}/>
              </div>

              <div
                className='two-type-wrapper'
              >
                <MeetingTypeInput setChecked={setCheckedOption} type={3} text={t("Team Check-in")}/>
                <MeetingTypeInput setChecked={setCheckedOption} type={4} text={t("Mission Statement Check")}/>
              </div>

            </section>



          </section>

          <section
            id = 'participants'
          >            
            <p
              className='input-label'
            >
              {t("Enter the emails of everyone you want to participate.")}*
              <br />
              {t('They will be notified via email as soon as you start a regular meeting.')}
            </p>

            <p
              id = 'participants-label-2'
            >
              {t("You can always edit the participants list.")}
            </p>

            <section
              id = 'participants-input'
            >
              <div
                id = 'participants-list'
              >
                <RegularMeetingEmailInput
                  emailsList={emailsList}
                  setEmailsList={setEmailsList}
                  invalidText={invalidText}
                  setInvalidText={setInvalidText}
                />
                <section
                  id = 'participants-list-emails'
                >
                  {
                    !emailsList || emailsList.length <= 0? 
                      <span
                        id = 'no-emails-p'
                      >
                        {t("You haven't listed any emails yet!")}
                      </span>
                    :
                      emailsList.map((email, index) => (
                        <RegularMeetingEmail 
                          email       = {email}
                          index       = {index}
                          removeEmail = {() => {
                            (emailToRemove: string) => {
                              setInvalidText('');
                              const newList = [...emailsList];
                              const indexToRemove = newList.indexOf(emailToRemove);
                              newList.splice(indexToRemove, 1)
                          
                              setEmailsList(newList);
                            }
                          }}
                          key         = {index}
                        />
                      ))

                  }
                </section>  
              </div>
            </section>

          </section>

          <section
            id = 'submit-form-cnt'
          >
              <button
                id = 'submit-form-btn'
                className={`${isSubmitable ? 'btn-active' : ''}`}
                title = {
                  isSubmitable ?
                    t('Create meeting.') || ''
                  :
                    t('Please fill all fields.') || ''
                }
              >
                <div
                  id = 'loading-wheel-cnt'
                >
                  <GIFLoading
                    show={isSubmitLoading}
                  />
                </div>
                {t('Create')}
              </button>

          </section>

        </form>
      </main>
    </>
  )
}
