import * as React from 'react';

export default function NavElement(props: {
  view      : string,
  viewState : string,
  viewName  : string
}){
  return (
    <div
      className={`nav-element ${props.viewState === props.view ? 'active' : ''}`}
      onClick={() => window.location.href = `#${props.view}`}
      id = {props.view}
    >
      <span
        className='nav-element-text'
      >
        {props.viewName}
      </span>
      <hr 
        className='nav-element-hr'
      />
    </div>
  )
}
