import { Alert } from '@mui/material'
import '../styles/QuestionnaireInfoPage.css'
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import QuestionnaireLinks from '../components/QuestionnaireLinks/QuestionnaireLinks';
import QuestionnaireBasicData from '../components/Questionnaire/QuestionnaireBasicData';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavBackLink from '../components/NavBackLink/NavBackLink';

export default function QuestionnaireInfoPage(){

  type displayStyle = 'none' | 'block' | 'flex';

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const linksWrapperRef = React.useRef<HTMLDivElement>(null)
  const [ areParamsInvalid, setAreParamsInvalid ] = React.useState<undefined | boolean>(undefined)
  const [ qnrType, setQnrType ] = React.useState<number | undefined>();
  const [ regularMeetingOfQnr, setRegularMeetingOfQnr ] = React.useState('');
  const { qnrId } = useParams();
  const visible = true;

  const [copyAlertDisplay, setCopyAlertDisplay] = React.useState<displayStyle>('none');
  
  const { t } = useTranslation()

  const initializeParams = () => {
    if(!qnrId){
      setAreParamsInvalid(true);
      return;
    }
      
      setAreParamsInvalid(false);
  }
  
  React.useEffect(initializeParams, [])

  React.useEffect(() => {
    axios
    .get(`${baseUrl}.netlify/functions/get-questionnaire-by-id`, {
      params: {
       qnrId: qnrId 
      }
    }).then( (response) => {
      setRegularMeetingOfQnr(response.data.regularMeetingId);
      return setQnrType(response.data.type);
    }).catch(() => setQnrType(1));

  }, [])
  
  return (
    <>
      {
        areParamsInvalid ?
          <>
            <span
    
            >
              {t(
                `${areParamsInvalid !== undefined ? 
                    "The questionnaire you are trying to view is invalid. "
                  :
                    "Loading..."
                }`
              )}
            </span>
          </>
        :
          <main>
            <NavBackLink
              pointsLeft = {true}
              to = {
                regularMeetingOfQnr
                  ?`/dashboard/regular-meeting/${regularMeetingOfQnr}#qnrs`
                  :'/dashboard#2'
              }
              text = 'Back to questionnaires'
              fontSize = {2}
            />
            <section
              id = 'general-info-cnt'                          
            >
              <QuestionnaireBasicData
                customId={qnrId || ''}                 
                headingFontSize='4rem'             
              />        
            </section>
              
            <section
              style={{
                width: '98%',
                maxWidth: '500px'
              }}
            >
              <QuestionnaireLinks 
                linksWrapperRef={linksWrapperRef}
                visible={visible}
                qnrIdParam={qnrId || ''}
                setCopyAlertDisplay={setCopyAlertDisplay}
              />
            </section>
          </main>
      }

      <Alert
            id="copy-alert"
            icon={
              <CheckCircleOutlineIcon
                style={{ color: 'black' }}
                fontSize="inherit"
              />
            }
            style={{ display: copyAlertDisplay }}
            severity="info"
            onClose={() => {
              setCopyAlertDisplay('none');
            }}
          >
            Item copied to clipboard successfuly
      </Alert>
    </>
  )
}
