import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { global } from '../types/global';
import '../styles/VotesDataComponent.css';
export default function  VotesDataComponent(props: {
  showVotes?: boolean,
  regularMeeting: RegularMeetingView
}) {
  
  const { t } = useTranslation();

  const [avgVotesPerQnr, setAvgVotesPerQnr] = React.useState(0);
  const [totalVotes, setTotalVotes] = React.useState<number>(-1);

  const [avgVotes, setAvgVotes] = React.useState<{
    total: number,
    count: number
  }[]>([])

  React.useEffect(() => {

    if(props.showVotes === undefined)
      props.showVotes = true;

    const votes : {
      total: number,
      count: number
    }[] = [];
    let _totalVotes = 0;
    props.regularMeeting?.questionnaires.forEach((qnr) => {
      _totalVotes += qnr.votes?.length || 0;
      qnr.votes?.forEach((vote) => {
        vote.questions.forEach((question, index) => {
          if(!votes[index]){
            votes[index] = {
              total: 0,
              count: 0
            };
          }
          votes[index].count ++;
          if(question.value > 0)
            votes[index].total += question.value;
        })
      
      })
    })
    for(const vote of votes){
      vote.total = Math.floor(vote.total / vote.count);
    }
    setTotalVotes(_totalVotes);
    setAvgVotesPerQnr(_totalVotes / props.regularMeeting.questionnaires.length);
    setAvgVotes(votes);
  }, [])

    return (
            <div
                id = 'votes-data-cnt'
                className={props.showVotes ? 'show' : ''}
            >
              {totalVotes > 0 && 
              <>
                <p
                    id = 'avg-votes-cnt'
                >
                    {t(`Average votes per questionnaire`)}:{` ${parseFloat(avgVotesPerQnr + '').toFixed(2)}`}

                </p>
                <p
                    id = 'avg-answer-p'
                >
                    {t('Average answer for question')}:
                </p>
                <div
                    id = 'avg-answer-cnt'
                >
                  <div
                    id = 'avg-answer-left'
                  >
                    {avgVotes.map((vote, index) => ( 
                      <span key = {index} > {`${t('Question')} #${index + 1}`}</span>
                    ))}
                  </div>
                  <div
                     id = 'avg-answer-right'
                  >
                    {avgVotes.map((vote, index) => (
                      <span key = {index}>{vote.total}%</span>
                    ))}
                  </div>

                </div>
                </>
                  || <p style = {{marginTop: '2rem'}}>{t('Your questionnaires have no votes')}</p>
              }
            </div>
  )
}
