import * as React from 'react';
import { useState } from 'react';
import { getUserSelectedLanguage } from '../functions/getUserLanguage';
import '../styles/login.css';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

export default function Login() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [checkEmail, setCheckEmail] = useState(0);
  const [saveLogin, setSaveLogin] = useState(false);

  const toggleSaveLogin = () => {
    setSaveLogin((curr) => {
      localStorage.setItem('keepLoggedIn', !curr + '')
      return !curr;
    })
  }; 
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setCheckEmail(1);
    await axios
      .get(baseURL + '.netlify/functions/signin', {
        params: { 
          email: email,
          language: getUserSelectedLanguage(),
          saveLogin
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCheckEmail(2);
        } else {
          alert('Failed to send the email. Try again later.');
          setCheckEmail(2);
        }
      })
      .catch(() => {
        alert('Failed to send the email. Try again later.');
        console.log('error');
      });
  };

  const h2Message = t("We promise, it's Qck and easy!");

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h1 className='heading-1'>{t('Sign in to your Qck account')}</h1>
        <h2 className='heading-2'>{h2Message}</h2>
        <form onSubmit={onSubmit}>
          <h3 className='heading-3'>Enter your email</h3>
          <input
            className="sendEmailTextField"
            required
            onChange={(e) => handleChange(e)}
            type="email"
            placeholder="email"
            value={email}
          ></input>
          <br />
          <div
            className='saveLoginPromptContainer'
          >
            <button
              className={`saveLoginCheckbox ${saveLogin ? 'on': 'off'}`}
              id='stay-logged-in'
              type='button'
              onClick={toggleSaveLogin}
            ></button>
            <label
              htmlFor='stay-logged-in'
              className='saveLoginLabel'
            >
              {t('Stay logged in')}
            </label>
          </div>
          <input
            className="sendEmailButton"
            type="submit"
            value="SUBMIT"
          ></input>
          {checkEmail === 2 && (
            <>
              <h2>Now check your email</h2>
              <h2 style={{ color: 'gray', fontSize: '1.2rem' }}>
                If you have not received an email yet, try submitting again
              </h2>
            </>
          )}
          {checkEmail === 1 && (
            <>
              <h2>Sending...</h2>
            </>
          )}
        </form>
      </div>
    </>
  );
}
