import style from './Questionnaire.module.css'
import DeleteIconSvg from '../../images/Icon_delete_cross.svg'
import QuestionnaireBasicData from './QuestionnaireBasicData'
import * as React from 'react'
import TypeIcon from '../TypeIcon';
import {t} from 'i18next';

export default function Questionnaire(props: {
  content : Questionnaire,
  setPopUpQnrId: React.Dispatch<React.SetStateAction<string>>
}){

  const togglePopUp = () => {
    props.setPopUpQnrId(props.content.customId);
  } 

  return (
    <li
      className={style.qnrItem}
      onClick={(event) => {
        const isDeleteButtonClicked = Array.from((event.target as HTMLElement).classList).some(str => str.includes('delQnrBtn'));
        if(!isDeleteButtonClicked){
          window.location.href = `/dashboard/questionnaire/${props.content.customId}`
        }
        }
      }
    >

      <QuestionnaireBasicData 
        customId={props.content.customId || ''}
        info={{
          keyStr: props.content.keyStr || '',
          //dateCreated: new Date(props.content.dateCreated || '').toLocaleDateString() || 'Unknown',
          dateCreated: props.content.dateCreated || 'Unknown',
          voteCount: props.content.votes?.length || 0,
          qnrName: props.content.qnrName || '',
          isShared: props.content.shared || false,
          type: props.content.type || 1
        }}
        togglePopUp={togglePopUp}
      />

    </li>
  )
}
