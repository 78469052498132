import { useTranslation } from 'react-i18next';
import style from './UserAgreementLink.module.css';
import * as React from 'react';

export default function UserAgreement() {
  const { t } = useTranslation();
  return (
    <div className={style.wrapper}>
      <span className={style.content}>
        {t('By using the services of this site, I agree to the end')}{' '}
        <a
          className={style.link}
          target='_blank'
          href={`${process.env.REACT_APP_BASE_URL}user-agreement`}
          rel='noreferrer'
        >
          {t('user license agreement')}
        </a>
      </span>
      <span className={style.content}>
        <a
          className={style.link}
          href={`${process.env.REACT_APP_BASE_URL}contact`}
          target='_blank'
          rel='noreferrer'
        >
          <br />
          {t('Contact us')}
        </a>
      </span>
    </div>
  );
}
