import * as React from 'react';
import { ReactComponent as IconComunication } from '../images/Icon_conference.svg';
import { ReactComponent as IconMeeting } from '../images/Icon_meeting.svg';
import { ReactComponent as IconMission } from '../images/Icon_step.svg';
import { ReactComponent as IconCheckIn } from '../images/Icon_work.svg';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export default function TypeIcon(props: {
  type : number
  id? : string,
  className? : string
}){

  const TypeIconMap : Record<number, ReactJSXElement>= {
    1 : <IconMeeting      className={props.className} id = {props.id} />,
    2 : <IconComunication className={props.className} id = {props.id} />,
    3 : <IconCheckIn      className={props.className} id = {props.id} />,
    4 : <IconMission      className={props.className} id = {props.id} />
  }
  
  return TypeIconMap[props.type] || TypeIconMap[1];
}