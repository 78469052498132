import '../styles/RegularMeetingInputType.css'

import * as React from 'react'
import TypeIcon from './TypeIcon';


export default function MeetingTypeInput(props: {
  text: string,
  type: number,
  setChecked: React.Dispatch<React.SetStateAction<number>>,
}){  

  return (
    <div
      className='meeting-type-option-cnt'
      onClick={() => props.setChecked(props.type)}
    >

      <button
        className={`meeting-type-option-button`}
        id = {`type-option-btn-${props.type}`}
        type='button'
        onClick = {
          () => {
            props.setChecked(props.type);
          }
        }
      >

      </button>


      <TypeIcon type={props.type || 1} className='qnr-type-icon'/>

      <span
        className='meeting-type-option-text'
      >
        {props.text}
      </span>
    </div>
  )
}
