export type ViewType = 'qnrs' | 'votes' | 'stats' | 'overview';
export const Views = ['qnrs', 'votes', 'stats', 'overview'];

export type NavElementType = {
  view: ViewType,
  name : string
}

export type RegularMeetingOverviewNumbers = {
  timeSinceLastQnr: {
    time: number,
    unit: 'Days' | 'Day' | ''
  },
  avgTimeBetweenQnrs: {
    time: number,
    unit: 'Days' | 'Day' | ''
  },
  totalQnrs: {
    count: number
  },
  totalVotes: {
    count: number
  }
}
