import * as React from 'react';
import {useAuth} from '../../hooks/useAuth';

function MyAccount() {
  const { user } = useAuth();

  return (
    <>
      <a
        href={user ? '/about-my-account/' + user : '/login'}
        id = 'nav-account-link'
      >
        {
          user || 'Login'
        }
      </a>
    </>
  );
}
export default MyAccount;
