import * as microsoftTeams from '@microsoft/teams-js'
import '../styles/TeamsQnrResults.css'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import axios from 'axios'
import * as React from 'react'

export default function TeamsDeleteQnrBtn(props: {
  theme? : string,
  setLoading? : React.Dispatch<React.SetStateAction<boolean>>
}) {
  
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation()

  const onLoad = () => {
    microsoftTeams.app.initialize().catch((err) => {
      window.location.href = ''
    })
  }

  useEffect(onLoad, [])

  const deleteQnr = () => {
    localStorage.removeItem('last-page')
    microsoftTeams.app.getContext().then(context => {
      const body = {
        meetingId : context.meeting?.id,
        userEmail : context.user?.userPrincipalName        
      }
      axios
      .post(`${baseUrl}.netlify/functions/teams-del-qnr`, 
        body        
      ).then(response => {
        localStorage.removeItem('last-page')
        window.location.href = '/ms-teams/pages/loading'
      })

      props.setLoading && props.setLoading(true);
   })
  }

  return (
    <button
      className={`redirect-btn ${props.theme}`}
      onClick={
        deleteQnr
      }
    >
      {t("New questionnaire")}
    </button>     
  )
}