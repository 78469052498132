import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../styles/aboutMyAccount.css';
import style from './MyAccount.module.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {useAuth} from '../../hooks/useAuth';

function AboutMyAccount() {
  const [ isLoading, setIsLoading ] = React.useState(false);

  const navigate = useNavigate();
  const { email } = useParams();
  const { t } = useTranslation();

  const { logout } = useAuth();

  const baseURL = process.env.REACT_APP_BASE_URL;

  const logOut = () => {
    logout()
    .then(() => {
      navigate('/');
    })
    .catch((e) => {
      console.log(e);
      localStorage.setItem('afterLogInLocation', window.location.href);
      navigate('/login');
    }).finally(() => setIsLoading(false));

    setIsLoading(true);
  };

  const navigateToDashboard = () => {
    window.location.href = '/dashboard'
  }

  return (
    <>
      <main
        className={style.main}
      >
        <div
          className={style.loadingOverlay}
          style={{
            display: isLoading ? 'block' : 'none'
          }}
        ></div>
        <span
          className={style.heading}
        >
          {t('Your email')}:
        </span>
        <span
          className={style.email}
        >
          {email}
        </span>

        <ul
          className={style.actionButtonsContainer}
        >
          <li
            className={style.buttonContainer}
          >
            <p
              className={style.buttonLabel}
            >
              {t('Concerned that someone other than you is using your account?')}
            </p>
            <button
              className={style.button}
              onClick={logOut}
            >
              {t('Log me out from any device')}
            </button>
          </li>
          <li
            className={style.buttonContainer}
          >
            <p
              className={style.buttonLabel}
            >
              {t('Want to look at your regular meetings and previous questionnaires?')}
            </p>
            <button
              className={style.button}
              onClick={navigateToDashboard}
            >
              {t('Go to Dashboard')}
            </button>
          </li>
        </ul>
      </main>
    </>
  );
}
export default AboutMyAccount;
