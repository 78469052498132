import * as React from 'react';
import Dots from './Dots';
import ConfirmDeletePopup from '../components/ConfirmDeletePopup/ConfirmDeletePopup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import QuestionnairesList from './QuestionnairesList';
import confirmDeletePopupStyle from '../components/ConfirmDeletePopup/ConfirmDelete.module.css';

export default function DashboardQnrs(){

  const [loaded, setLoaded] = React.useState(false);
  const [qnrs, setQnrs] = React.useState<Questionnaire[]>([]);

  const [popUpQnrId, _setPopupQnrId] = React.useState<string>('');

  const setPopupQnrId: React.Dispatch<React.SetStateAction<string>> = (value: string | ((prevValue: string) => string)) => {
    const error = new Error();
    const stack = error.stack || '';
    const stackLines = stack.split('\n');
                
    const callerLine = stackLines[2];
    _setPopupQnrId(value);
    return '';
  }

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation()

  const injectHandleClickOutsidePopup = () => {
    document.getElementById('root')?.addEventListener('click', (event) => {
      const classList = (event.target as HTMLElement).classList;
      const shouldClosePopup = Array.from(classList).some(str => str.includes('ignoreClose'));
      console.log(shouldClosePopup);
      if(!shouldClosePopup) return;

      setPopupQnrId('');
      
    })

  }   

  React.useEffect(injectHandleClickOutsidePopup, [])

  const fetchUserQuestionnaires = () => {
    axios
    .get(`${baseUrl}.netlify/functions/get-questionnaires`)
    .then(result => {
      let resultQnrs = result.data.qnrs
      if(resultQnrs){
        resultQnrs = resultQnrs.filter((item: any) => (item as any).qnrName)
        setQnrs(resultQnrs)
      }  
      setLoaded(true);
    }).catch((error) => {
      localStorage.setItem('afterLogInLocation', window.location.href);
      window.location.href = '/login'
    })

  } 

  React.useEffect(fetchUserQuestionnaires, [])
  React.useEffect(() => console.log(popUpQnrId), [popUpQnrId]);

  return (
    <>
       <div
        className={`${confirmDeletePopupStyle.confirmDeleteCnt} ${confirmDeletePopupStyle.ignoreClose}`}
        style={{
          display: popUpQnrId ? 'flex' : 'none'
        }}
      >
        {
          popUpQnrId ?
            <ConfirmDeletePopup
              qnrId =         {popUpQnrId}
              setPopUpQnrId = {setPopupQnrId}
            />
          :
            <></>
        }
      </div>

      <div
        style={{
          position: 'fixed',
          left: '0',
          top: '0',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: popUpQnrId ? 'block' : 'none',
          zIndex: '998'
        }}
      >

      </div>


      <main
        style={{
          flexGrow: loaded && (!qnrs || qnrs.length <= 0) ? '1': '0'
        }}
      >
      {
        !loaded ?            

          <span style = {{
            fontSize : "5rem",
            height: "80vh"
          }}>
            {t('Loading')}
            <Dots fontSize={"5rem"}/>
          </span>              

        :

        <>
          <section id = 'qnrs'>
              {
                qnrs.length === 0 ? 
                  <span
                    style={{
                      fontSize: '2.5rem',
                      textAlign: 'center',
                      whiteSpace: 'normal',
                      marginTop: '7rem'             
                    }}
                  >                  
                    {t("You have no questionnaires. You can start creating questionnaires ")}
                    <a 
                      href="/"
                      style={{
                        
                      }}
                    >
                      {t("here")}.
                    </a>
                  </span>
                  :
                  <>
                    <span
                      className = 'heading'
                    >
                      {t('Your Questionnaires')}
                    </span>
                    <QuestionnairesList setPopUpQnrId={setPopupQnrId} />
                  </>
              }
          </section>    
        </>
      }
      </main>
    </>
  )
}
