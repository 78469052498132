import * as React from 'react';
import GIFLoading from './GIFLoading';
import '../styles/LoadingOverlay.css'

export default function LoadingOverlay(props: {
  show: boolean
}){
  

  return (
    <div
      id = 'loading-overlay'
      className={props.show ? 'show' : ''}
    >
      <GIFLoading
        show = {props.show}
      />
    </div>
  )
}