import * as React from 'react';
import ArrowRight from '../../images/Icon-arrow-down.svg';
import { useTranslation } from 'react-i18next';
import style from './NavBackLink.module.css';

export default function  NavBackLink(props: {
  to: string,
  pointsLeft: boolean,
  text: string,
  fontSize?: number
}) {

  const { t } = useTranslation();

  const fontSize = `${props.fontSize}rem`
  return (
    <div
      className={style.container}
    >
      <a
        className={style.link}
        href = {props.to}
      >
        <img src={ArrowRight}
          alt = 'Arrow'
          className={ props.pointsLeft ? style.arrowLeft : style.arrowRight }
          style = {{
            width: fontSize,
            height: fontSize
          }}
        /> 
        <span
          className={style.textContent}
          style={{
            fontSize: fontSize 
          }}
        >
          {t(props.text)}
        </span>
      </a>
    </div>
  )
}
