import * as React from 'react';
import { global } from '../types/global';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Dots from '../components/Dots';
import '../styles/RegularMeeting.css'
import '../styles/RegularMeetingEmailInput.css'
import IconDelete from '../images/Icon_delete_cross.svg'
import IconEdit   from '../images/Icon_edit.svg'
import GIFLoading from '../components/GIFLoading';
import ConfirmDeletePopup from '../components/ConfirmDeletePopup/ConfirmDeletePopup';
import { useTranslation } from 'react-i18next';
import { ViewType, Views, NavElementType } from './RegularMeeting/types';
import NavElement from './RegularMeeting/NavElement';
import Overview from './RegularMeeting/Overview';
import DeleteRegularMeetingPopup from './RegularMeeting/DeleteRegularMeetingPopup';
import NavBackLink from '../components/NavBackLink/NavBackLink';
import QuestionnairesList from '../components/QuestionnairesList';
import ConfirmDeletePopupStyle from '../components/ConfirmDeletePopup/ConfirmDelete.module.css';

export default function RegularMeeting(){

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  const { customId } = useParams();

  const [ regularMeeting, setRegularMeeting ] = React.useState<globalThis.RegularMeetingView>()

  const [ view, setView ] = React.useState<ViewType>(window.location.hash.substring(1) as ViewType);
  const [ loading, setLoading ] = React.useState(true);
  const [ popUpQnrId, setPopUpQnrId ] = React.useState<string>('');
  const [ deletePrompted, setDeletePrompted ] = React.useState<boolean>(false);
  const [ editName, setEditName ] = React.useState<boolean>(false);
  const [ isEditNameLoading, setIsEditNameLoading ] = React.useState<boolean>(false);
  
  const nameInputRef = React.useRef<HTMLInputElement>(null)

  // These are the navigation elements that will render. 
  // This array is used for rendering the navbar
  const mainNavElements : NavElementType[] = [
    {
      view: 'qnrs',
      name : t('Questionnaire History')
    },
    {
      view: 'overview',
      name: t('Overview')
    },
    {
      view: 'votes',
      name: t('Votes')
    }
    
  ]

  React.useEffect(() => {
    const handleViewChange = () => {
      const hash = window.location.hash.substring(1) as ViewType;
      setView((view) => {
        console.log(view)
        return hash
      });
    }
    if(!view || !Views.includes(view)){
      window.location.href = '#overview';
    }
    window.addEventListener('hashchange', handleViewChange);

    return () => {
      window.removeEventListener('hashchange', handleViewChange);
    }
  }, [])

  React.useEffect(() => {
    if(!customId) {
      window.location.href = '';
    }

    const url = `${baseUrl}.netlify/functions/get-regular-meetings`
    axios
    .get(url, {
      params: {
        customId: customId
      }
    }).then(result => {
      const response = result.data as RegularMeetingView[]
      let meeting : RegularMeetingView;
      if(response && response.length > 0){
        meeting = response[0]
        setRegularMeeting(meeting);
      }

      return setLoading(false)
    }).catch((reason) => {
      localStorage.setItem('afterLogInLocation', window.location.href);
      window.location.href = '/login'
    })
  }, [])

  const changeRegularMeetingName : React.FormEventHandler = (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target as HTMLFormElement)
    
    const params = {
      name: formData.get('rm-name') || '',
      customId: regularMeeting?.customId || ''
    }

    const url = `${baseUrl}.netlify/functions/change-regular-meeting-name`
    axios
    .get(url, {
      params: params
    })
    .then((response) => {
      return window.location.reload();
    })
    setIsEditNameLoading(true);
  }

  return (
    <>
      <div
        className='ignore-close'
        id = {ConfirmDeletePopupStyle.confirmDeleteCnt}
        style={{
          display: popUpQnrId || deletePrompted ? 'flex' : 'none'
        }}
      >
        {
          popUpQnrId &&
            <ConfirmDeletePopup
              qnrId         = {popUpQnrId}
              setPopUpQnrId = {setPopUpQnrId}
            />
        }
        {
          deletePrompted &&
          <DeleteRegularMeetingPopup
            customId           = {regularMeeting?.customId || ''}
            setDeletePrompted  = {setDeletePrompted} 
            regularMeetingName = {regularMeeting?.name || ''} 
          />
        }
      </div>

      <div
        style={{
          position: 'fixed',
          left: '0',
          top: '0',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: popUpQnrId || deletePrompted ? 'block' : 'none',
          zIndex: '998'
        }}
      >

      </div>

      <main
        className='main'
      >
        {
        !loading && regularMeeting ?
          <>
            <NavBackLink
              pointsLeft = {true}
              to = '/dashboard#1'
              text = 'Back to dashboard'
              fontSize = {2}
            />
            <section
              id = 'heading-cnt'
            >

              <form
                id = 'heading-h1-cnt'
                onSubmit={changeRegularMeetingName}
              >
                <input
                  id = 'heading-h1'
                  className={editName ? 'edit' : ''}
                  defaultValue = {regularMeeting.name}
                  readOnly = {!editName}
                  ref={nameInputRef}
                  autoComplete='off'
                  autoCorrect='false'
                  name = 'rm-name'
                />                

                <div
                  id = {`edit-btns-cnt`}
                  className={editName ? 'edit' : ''}
                >
                  <button
                    className='edit-btn cancel'    
                    type = 'button'
                    onClick={() => setEditName(false)}
                  >
                    {t('Cancel')}
                  </button>

                  <button
                    className={`edit-btn save ${isEditNameLoading ? 'loading' : ''}`}
                  >
                    <div
                      className={`gif-loading-cnt`}                    
                    >
                      <GIFLoading 
                        show={isEditNameLoading}                        
                      />
                    </div>
                    {t('Save')}
                  </button>
                </div>
              </form>

              <div
                id = 'change-data-btn-cnt'
              >
                <button
                  className='change-data-btn'
                  id = 'edit-rm-btn'
                  style={{
                    backgroundImage: `url(${IconEdit})`
                  }}
                  onClick={() => setEditName((curr) => { 
                    if(curr) return false;
                    console.log(curr)                    
                    nameInputRef.current?.focus();           
                    return !curr;
                  })}
                >
                </button>
                <button
                  className='change-data-btn'
                  id = 'delete-rm-btn'
                  style={{
                    backgroundImage: `url(${IconDelete})`
                  }}
                  onClick={() => setDeletePrompted(true)}
                >
                  
                </button>
              </div>

            </section>

            <section
              id = 'nav'
            >
              <section
                id = 'nav-main'
              >
                {
                  mainNavElements.map( (item, index) => (
                    <NavElement 
                      view      = {item.view}
                      key       = {index}
                      viewState = {view}
                      viewName  = {item.name}
                    />
                  ))
                }
              </section>
            </section>

            <section
              id = 'content'
            >
              {
                view === 'qnrs' && (
                  regularMeeting.questionnaires && regularMeeting.questionnaires.length > 0 ?
                    <QuestionnairesList regularMeetingId={regularMeeting.customId} />
                  :
                    <span
                      style = {{
                        fontSize: '4rem',
                        margin: 'auto',
                        textAlign: 'center'
                      }}
                    >
                      {t('You have no questionnaires started from this regular meeting! ')}
                    </span>
                ) ||
                view === 'overview' && (
                    <Overview
                      regularMeeting={regularMeeting}
                      setRegularMeeting={setRegularMeeting}
                    />
                )
              }
            </section>

          </>
        :
        loading ?
          <span
            id = 'loading'
          >
            Loading<Dots fontSize='5rem'/>
          </span>
        :
          <span
            id = 'loading'
          >
            {t('No such regular meeting found! ')} 
          </span>
        }

      </main>

    </>
  )
}
