import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './SetName.module.css';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';

export default function SetName(props: {
  qnrId: string | number;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  qnrType: string;
}) {

  const [qnrName, setQnrName] = useState('');
  const [ loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQnrName(e.target.value);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (qnrName) {
      saveToDatabase();
    }
  };
  const saveToDatabase = () => {
    setLoading(true);
    axios
    .put(`${baseURL}.netlify/functions/set-qnr-name`, {
      params: { qnrId: props.qnrId, qnrName: qnrName },
    })
    .then(() => {
      props.setVisible(true);
    })
    .finally(() => setLoading(false));
  };

  
  useEffect(() => {
    axios
    .get(baseURL + '.netlify/functions/get-results', {
      params: {
        qnrId: props.qnrId,
      },
    })
    .then((response) => {
      const questionnaire = response.data;
      if (questionnaire.qnrName) {
        const responseName = questionnaire.qnrName.toString();
        setQnrName(responseName);
        props.setVisible(true);
      }
    })
  }, [baseURL, navigate, props]);

  let placeholder =
    t(
      'Team name'
    ) || '';

  if (props.qnrType === t('Start Meeting Follow-Up')) {
    placeholder =
      t(
        'Working question'
      ) || '';
  }
  if (props.qnrType === t('Start Mission Statement Check')) {
    placeholder =
      t(
        'Organization name',
      ) || '';
  }

  return (
    <>
      <form
        onSubmit={(e) => handleSubmit(e)}
        className={style.form}
        style={{ display: 'flex' }}
      >
        <div
          className={style.loadingOverlay}
          style={{display: loading ? 'block' : 'none'}}
        ></div>
        <input
          className={style.input}
          name="name-setter"
          type="text"
          placeholder={'*' + placeholder}
          // maxLength={80}
          onChange={(e) => handleChange(e)}
          value={qnrName}
        ></input>
        <button
          className={style.submit}
          type="submit"
        >
          {t('Continue')}
        </button>
      </form>
    </>
  );
}
