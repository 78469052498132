
import axios from 'axios'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import TeamsNavbar from '../components/TeamsNavbar'
import '../styles/TeamsQnrResults.css'
import { setupUserTheme } from '../functions/getUserTheme'
import { ReactComponent as ExternalLinkIcon } from '../images/Icon-external-link.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TeamsVerifyView from '../components/TeamsVerifyView'
import LoadingOverlay from '../components/LoadingOverlay'
import { global } from '../../src/types/global'

export default function TeamsQnrAnswer(){

  const { t } = useTranslation()
  const baseUrl = process.env.REACT_APP_BASE_URL
  const { meetingId } = useParams()

  const [theme, setTheme] = React.useState<TeamsColorTheme>('default')
  const [loading, setLoading] = React.useState(false);

  const getQnrInMeeting = () => {
    return new Promise( (res, rej) => {
      axios
      .get(`${baseUrl}.netlify/functions/teams-get-qnr-id`, {
        params: {
          meetingId: meetingId
        }
      }).then(response => {
        res(response.data.qnrId)
      }).catch((err) => rej(err))
    })    
  }

  const checkIsDeleted = () => {
    console.log(`INVOKING 'IS DELETED'. `)
    getQnrInMeeting().then(qnrId => {
      console.log(`QNRID: ${qnrId}`)
      if(!qnrId){
        const pathname = '/ms-teams/pages/loading' 
        localStorage.removeItem('last-page')
        window.location.href = pathname
      }
    })
  }

  
  const redirectToQnr = async () => {
    
    setLoading(true);
    const qnrId = await getQnrInMeeting()
    if(!qnrId){
      localStorage.removeItem('last-page')
      setLoading(false);
      window.location.href = '/ms-teams/pages/loading'
      return
    }
    
    setLoading(false);
    window.open(`${baseUrl}${qnrId}`, '_blank')
  }

  useEffect(() => {
    setupUserTheme(setTheme)
    setInterval(checkIsDeleted, 10000)
  }, [])
  
  return(
    <div
      style = {{
        display: 'flex',
        flexDirection: 'column'
      }}
    > 

      <LoadingOverlay
        show = {loading}
      />
      <TeamsVerifyView/>
      <TeamsNavbar theme={theme}/>

      <main
        style= {{
          width : '80%',
          alignSelf: 'center',
          justifyContent : 'center',
          alignItems : 'center',      
        }}
      >
        <p
          style = {{
            width: '80%',
            fontSize: '2rem',
            fontWeight : '600',
            textAlign : 'center'
          }}
          className={theme}
        >
          {t('Someone in your meeting has started a questionnaire!')}
        </p>

        <p
          className={`redirect-btn-label ${theme}`}
        >
          {t('Check it out here')}
        </p>

        <button
          className = 'redirect-btn'
          onClick = {redirectToQnr}
        >
          <p>
            {t("Click to Answer")}
          </p>
        
          <ExternalLinkIcon/>
        </button>
        

      </main>
      
    </div>
  )
}