import * as React from 'react';
import {Outlet} from 'react-router-dom';
import DashboardNav from './DashboardNav';

export default function  DashboardRoutes() {
  return (
    <>
      <DashboardNav />

      <Outlet />
    </>
  )
}
